import $ from 'jquery';
import Cookies from 'js-cookie';
require('normalize.css/normalize.css');
require('../css/main.scss');
import 'regenerator-runtime/runtime';
import { db, getDoc, doc, auth, registerUser, getCompanyByName, createCompany, serverTimestamp, onAuthStateChanged, signOutUser } from '../js/firebaseConfig';
import { checkAuthAndRedirect } from '../js/redirect.js';

$(document).ready(function() {

  //console.log('Espace Demo');

});